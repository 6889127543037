import React, { useState, useEffect } from 'react';
import image from '../../images/logo.jpg';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faEnvelope, faMobile, faLocation, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import TopDesc from './TopDesc';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isTreatmentsDropdownOpen, setIsTreatmentsDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleTreatmentsDropdown = () => {
    setIsTreatmentsDropdownOpen(!isTreatmentsDropdownOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };


  return (
    <>
     
      <TopDesc/>
      <nav className={scrolled ? 'navbar navbar-scrolled' : 'navbar'}>
        <div className="logo">
          <img src={image} alt="ABC Logo" />
        </div>
        <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
          <li><Link to="/about" onClick={handleLinkClick}>About Us</Link></li>
          
          <li><Link to="/ourservice" onClick={handleLinkClick}>Services</Link></li>
          <li className="dropdown" onClick={toggleTreatmentsDropdown}>
            <span>Product</span>
            <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
            {isTreatmentsDropdownOpen && (
              <ul className="dropdown-menu">
                <li><Link to="/MedicalEq" onClick={handleLinkClick}>Medical Equipments</Link></li>
                <li><Link to="/medicalcon" onClick={handleLinkClick}>Medical Consumables</Link></li>
                <li><Link to="/personal" onClick={handleLinkClick}>Personal Healthcare</Link></li>
                <li><Link to="/hospital" onClick={handleLinkClick}>Hospital Furnitures</Link></li>
                <li><Link to="/medicaldress" onClick={handleLinkClick}>Medical Dressing</Link></li>

              </ul>
            )}
          </li>
          <li><Link to="/contact" onClick={handleLinkClick}>Contact Us</Link></li>
          
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
