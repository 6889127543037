import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import './Social.css'

function Social(){
    return(
        <>
        <div className='social-buttons'>
            <ul>
                <li><a href='https://www.facebook.com/medicalhintsnepal'>Facebook  <FontAwesomeIcon icon={faFacebookSquare} size="2x" className="icon" /></a></li>
                <li><a href='https://www.instagram.com/medicalhintsnepal'> Instagram<FontAwesomeIcon icon={faInstagram} size="2x" className="icon instagram-icon" /></a></li>
            </ul>
        </div>
        </>
    )
}
export default Social
    