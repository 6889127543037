import React from 'react';
import './TopDesc.css'

const TopDesc = () => {
    return (
        <div className="contact-info">
            <div className="phone">
                <i className="fa-brands fa-whatsapp"></i> {/* Assuming you're using Font Awesome for icons */}
                <span>9808962431, 9868133933</span>
            </div>
            <div className="email">
                <i className="fas fa-envelope"></i>
                <a href="https://mail.google.com/mail/u/0/#inbox">medicalhintsnepal@gmail.com</a>
            </div>
            <div className="social-icons-1">
                <a href="https://www.facebook.com/medicalhintsnepal" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/medicalhintsnepal" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/medical-hints-nepal-07857b303/" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-linkedin"></i>
                </a>
            </div>
        </div>
    );
}

export default TopDesc;
