import React from 'react';
import './StartModal.css';
import logoImage from '../../images/1711863201038-removebg-preview.png'; // Import your image

const StartModal = ({ closeModal }) => {
  return (
    <div className="start-modal">
      <div className="start-modal-content">
        <img src={logoImage} alt="Medical Hints Nepal Logo" className="start-logo" />
        <div className='head'>
          <h2>Welcome to Medical Hints Nepal</h2>
          <p>Explore our services and products to find what you need.</p>
        </div>
        <span className="start-close" onClick={closeModal}>&times;</span>
      </div>
    </div>
  );
};

export default StartModal;
