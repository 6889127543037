import React, { useState } from 'react';
import './MedicalEq.css';
import { Link } from 'react-router-dom';
import heini from '../../images/littmansteth.png';
import oxy from '../../images/obj2.png';
import abs from '../../images/obj3.png';
import tynor from '../../images/obj4.png';
import Littmann from '../../images/tynor.png';
import rionet from '../../images/tynorbelt.png';
import preg from '../../images/backsupport-removebg-preview.png';
import Footer from '../../element/Footer/Footer';

const MedicalEq = () => {
  const [products, setProducts] = useState([
    {
      name: 'Littmann Stethoscope Classic III Plum / Mirror Finish / Violet Stem',
      description: 'Littmann Stethoscope Classic III Plum Tubing (27”/68.5cm) Mirror Finish / Violet Stem Introducing the brand new Littmann Stethoscope Classic III Plum / Mirror Finish / Violet Stem. With the first major upgrade of the iconic Littmann stethoscope in 18 years, the new industry standard has arrived! The Classic III stethoscope brings new design, materials, and technology to the Littmann stethoscope series that’s been used and trusted by millions of medical professionals worldwide for decades.',
      image: heini,
      type: 'Littmann'
    },
    {
      name: 'Ciel Blue',
      description: 'LITTMANN CLASSIC III: Hear frequency sounds by simply adjusting how much you press on the chestpiece. The bell stays clear of dirt and debris by keeping it covered with the small diaphragm.',
      image: oxy,
      type: 'Littmann'
    },
    {
      name: '3M™ Littmann® - Classic III™ Stethoscope - Pearl Pink',
      description: '3M™ Littmann® Classic III™ Stethoscope This is the perfect all-around stethoscope -available in vibrant colors Latex Free 5 Year Warranty Monitor and assess a wide range of patients Detect normal and abnormal sounds and rhythms  Vibrant and fun colors and finishes are available Designed for use with adult and pediatric patients Tunable, dual- sided stainless steel chestpiece.',
      image: abs,
      type: 'Littmann'
    },
    {
      name: 'Tynor Lumbo Back Rest Urbane',
      description: 'Tynor Lumbo-Back Rest Urbane is portable back support, which maintains the lumbar spine in its neutral position.',
      image: tynor,
      type: 'Tynor'
    },
    {
      name: ' TYNOR ANKLE BINDER URBANE',
      description: 'The Tynor Ankle Binder Urbane is a trendy and effective device to support, compress and partially immobilize the ankle following injury or sprain to control pain, oedema or inflammation. Composed of two components, compression sleeve and elasticized strap. The elasticized strap can be conveniently wrapped in a figure of 8 for strong and effective ankle support.',
      image: Littmann,
      type: 'Tynor'
    },
    {
      name: 'Tynor Rib Belt',
      description: 'Tynor Rib Belt, Size: XXL is a premium quality product from Tynor. Moglix is a well-known ecommerce platform for qualitative range of Waist & Abdomen Supports. All Tynor Rib Belt, Size: XXL are manufactured by using quality assured material and advanced techniques, which make them up to the standard in this highly challenging field.',
      image: rionet,
      type: 'Tynor'
    },
    {
      name: 'TYNOR PREGNANCY BACK SUPPORT',
      description: 'Tynor Pregnancy Back Support features an advanced design with technically correct materials. This allows the pregnant mother with both effective support and immobilization as well as excellent aesthetics and comfort.',
      image: preg,
      type: 'Tynor'
    },
  ]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');


  const handleLearnMore = (index) => {
    setSelectedProduct(products[index]);
  };

  const handleClose = () => {
    setSelectedProduct(null);
  };

  const handleFilter = (type) => {
    if (type === 'all') {
      setFilterType(null);
    } else {
      setFilterType(type);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product => {
    if (!filterType || product.type === filterType) {
      return product.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });


  return (
    <>
      <section className='medical-section' id='product'>
        <h2>Our Products</h2>
        <div className="filter-buttons">
          <button onClick={() => handleFilter('all')}>All</button>
          <button onClick={() => handleFilter('Littmann')}>Littmann</button>
          <button onClick={() => handleFilter('Tynor')}>Tynor</button>
        </div>
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="medical-grid">
          {filteredProducts.map((product, index) => (
            <div key={index} className="medical-card">
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              {/* <p>{product.description}</p> */}
              <button onClick={() => handleLearnMore(index)} className="medical-btn">
                Learn More
              </button>
            </div>
          ))}
        </div>
      </section>

      {selectedProduct && (
        <div className="product-modal">
          <div className="product-modal-content">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
            <h3>{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
            <Link to="./contact" className="buy-btn">
              Buy Now
            </Link>
          </div>
          <button className="close-btn" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}

      <Footer/>
    </>
  )
}

export default MedicalEq;
