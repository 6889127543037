import './App.css';
import { useState, useEffect } from 'react';
import Navbar from './Components/element/Navbar/Navbar';
import About from './Components/Pages/About/About';
import Home from './Components/Pages/Home/Home';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Loader from './Components/element/Loader/Loader';
import OurService from './Components/Pages/OurService/OurService';
import OurContact from './Components/Pages/OurContact/OurContact';
import Treatment from './Components/Pages/Treatment/Treatment';
import Local from './Components/Pages/Hospital/Local';
import International from './Components/Pages/Hospital/International';
import ServiceDetail from './Components/element/Service/ServiceDetail';
import OrganTransplant from './Components/Pages/Treatment/OrganTransplant';
import Procedure from './Components/Pages/Treatment/Procedure';
import OurTeams from './Components/Pages/OurTeams/OurTeams';
import Personal from './Components/Pages/OurProduct/Personal';
import MedicalEq from './Components/Pages/OurProduct/MedicalEq';
import MedicalCon from './Components/Pages/OurProduct/MedicalCon';
import Hospital from './Components/Pages/OurProduct/Hospital';
import MedicalDressing from './Components/Pages/OurProduct/MedicalDressing';
import StartModal from './Components/element/Start/StartModal';

function App() {
  const [data, setData] = useState(false);
  const [loading, setLoading ] = useState(true);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setData(true);
      setLoading(false)
     } , 200);
  }, [])

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
     <div>
     {/*{loading && <Loader/>} */}
     {data && (
    <div>
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' exact element={<Home/>}/>
        <Route path='/about'  element={<About/>}/>
        <Route path='/ourservice' element={<OurService/>} />
        <Route path='/contact' element={<OurContact/>} />  
        <Route path='/personal' element={<Personal/>} />   
        <Route path='/MedicalEq' element={<MedicalEq/>}/>
        <Route path='/medicalcon' element={<MedicalCon/>}/>
        <Route path='/hospital' element={<Hospital/>}/> 
        <Route path='/medicaldress' element={<MedicalDressing/>}/>
      </Routes>
    </BrowserRouter>
    {showModal && <StartModal closeModal={closeModal} />}
    </div>
     )}
     </div>

    </>
  );
}

export default App;
