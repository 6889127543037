import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './HomeSlider.css';
import image1 from '../../images/stethoscope.jpg';
import image2 from '../../images/slide1-min.jpg';


const HomeSlider = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <div
          className="parallax-bg"
          style={{ backgroundImage: `url(${image1})` }}
        >
          <div className="slide-content">
            <h2>Littmann Stethoscope</h2>
            <p>Discover our range of high-quality Littmann stethoscopes, trusted by healthcare professionals worldwide.</p>
            <button>Read More </button>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          className="parallax-bg"
          style={{ backgroundImage: `url(${image2})` }}
        >
          <div className="slide-content">
            <h2>Point Of Care</h2>
            <p>Explore our point of care solutions designed to streamline healthcare delivery and improve patient outcomes.</p>
            <button>Read More</button>
          </div>
        </div>
      </Carousel.Item>
      
     
    </Carousel>
  );
};

export default HomeSlider;
