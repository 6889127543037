import React from 'react'
import './Serviceimage.css'
import image from '../../images/service.gif'

const Serviceimage = () => {
  return (
    <>
    <div className='ser-section'>
        <div className='ser-container'>
            <div className='ser-grid'>
                <div className='left-container'>
                    <img src={image} alt=''/>
                </div>
                <div className='right-container'>
                    <h1>More on Medical</h1>
                    <p>We offer an extensive catalog of medical equipment from trusted manufacturers. Our inventory includes a wide range of devices,
                      such as diagnostic equipment (like ultrasound machines, ECG monitors, and X-ray systems), 
                      patient monitoring systems, surgical instruments, anesthesia machines, and more.</p>
                </div>
            </div>
        </div>

    </div>
      
    </>
  )
}

export default Serviceimage
