import React from 'react'
import AboutHeroSection from '../../element/AboutSection/AboutHeroSection'
import Footer from '../../element/Footer/Footer'
import Objectives from '../../element/ObjectiveSection/Objectives'
import Blog from '../../element/Blog/Blog'
import Aboutimage from '../../element/AboutSection/Aboutimage'
import Scroll from '../../element/Scroll/Scroll'
import AboutText from '../../element/AboutSection/AboutText'
import Brand from '../../element/Brand/Brand'
import SerCard from '../../element/ObjectiveSection/SerCard'
import ServiceDetail from '../../element/Service/ServiceDetail'

const About = () => {
  return (
    <>
    <AboutText/>
    <ServiceDetail/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default About
