import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faChartLine, faUsers, faTasks, faMoneyBill, faHeart, faRecycle, faBuilding, faGears, faPlug, faBed } from '@fortawesome/free-solid-svg-icons';
import './ServiceDetail.css';

const ServiceDetail = () => {
  return (
    <section className="servicedetail-section">
        <h2>What We Offer</h2>
      <div className="servicedetail-container">
        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faHeart} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Medical Equipments</h3>
              <p className="servicedetail-description">Medical Hints has brought continued innovation to diagnostic cardiology solutions over the years. Today, our commitment to improving ECG analysis and developing new solutions is stronger than ever.</p>
            </div>
          </div>
        </div>

        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faBuilding} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Personal Healthcare</h3>
              <p className="servicedetail-description">SPHYGMOMANOMETER: Blood pressure is the pressure that is exerted by the blood upon the walls of the blood vessels. GLUCOMETER: High precision, less pain and innovation technology for monitoring blood glucose easily.</p>
            </div>
          </div>
        </div>

        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faGears} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Medical Consumables</h3>
              <p className="servicedetail-description">Neurostimulation Electrode pads are widely used for different purposes such as pain management, tissue repair, muscle relaxation and strengthening and so forth.</p>
            </div>
          </div>
        </div>

        {/* Add more service detail columns */}
        {/* Duplicate the above block and modify icon and content */}
        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faPlug} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Medical Dressings</h3>
              <p className="servicedetail-description">3M Particulate Respirator 8210 Plus, N95 is a disposable particulate respirator that is designed to help provide reliable respiratory protection of at least 95 percent filtration efficiency against certain non oil based particles.</p>
            </div>
          </div>
        </div>

        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faBed} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Hospital Furnitures</h3>
              <p className="servicedetail-description">Different type of care require different supporting equipment.Paramount Bed has a comprehensive products that provide for various needs and situations.</p>
            </div>
          </div>
        </div>

        <div className="servicedetail-column">
          <div className="servicedetail-card">
            <FontAwesomeIcon icon={faRecycle} className="servicedetail-icon" />
            <div className="servicedetail-content">
              <h3 className="servicedetail-title">Waste Management System</h3>
              <p className="servicedetail-description">DrCare specializes in providing quality waste management products and disposal solution for hospitals and healthcare institutions in Nepal.</p>
            </div>
          </div>
        </div>
        {/* End of additional service detail columns */}
      </div>
    </section>
  );
}

export default ServiceDetail;
