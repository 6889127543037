import React from "react"
import yasisimage from '../../images/yasis.jpg'
import ashrika from '../../images/lady2.jpg'
import arjun from '../../images/man.jpg'



const people = [
  {
      id: 1,
      image: yasisimage,
      quote:
      " I'm impressed with the quality of service provided by Medical Hints Nepal. Their team went above and beyond to meet our needs and deliver outstanding results.",
      name: "-  Yasis Chand ",
      title: ""
  },

  {
    id: 2,
    image: ashrika,
    quote:
    "MedicalhintsNepal is a great medical equipment supplier. Their customer service is outstanding- they are always available to answer questions and provide helpful advice.",
    name: "- Aashrika Phuyal",
    title: ""
},

{
  id: 3,
  image: arjun,
  quote:
  "I am so grateful for the excellent service provided by MedicalhintsNepal. They supplied me with high-quality medical equipment at an affordable price. The staff was friendly and helpful, making the whole process smooth and stress-free. Highly recommend this business to anyone in need of medical supplies!",
  name: "-  Dr.Arjun Bahadur Chand",
  title: ""
},

{
  id: 4,
  image: arjun,
  quote:
  "MedicalhintsNepal stands out as an exceptional medical equipment supplier, renowned for their outstanding customer service. Their commitment to being readily available to address inquiries and offer valuable advice sets them apart in the industry.",
  name: "-  Suyog Bhandari",
  title: ""
},


]
export default people

