import React from 'react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import './OfferService.css';

const OfferService = () => {
  return (
    <div className='service-wrapper'>
      <h1>Features and Services</h1>
      <div className='service-content-box'>
        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-house'></i>
          <h2>Home Delivery</h2>
          <p>At Medical Hints Nepal, we understand the importance of convenience and safety for our valued customers. That's why we offer reliable and efficient home delivery services for all your medical needs.</p>
        </motion.div>

        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-cart-shopping'></i>
          <h2>Free Delivery inside valley</h2>
          <p>For our customers inside the valley, we provide free delivery services. Enjoy the convenience of getting your medical supplies delivered to your home without any additional charges.Our home delivery service.</p>
        </motion.div>

        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-kit-medical'></i>
          <h2>Quality Product</h2>
          <p>At Medical Hints Nepal, we are committed to providing our customers with top-notch quality products.We understand the importance of healthcare and the impact it can have on your well-being</p>
        </motion.div>

        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-clock'></i>
          <h2>Less time exchange</h2>
          <p>At Medical Hints Nepal, we ensure you that the exchange is completed in a shorter duration, minimizing the waiting time for the customer.</p>
        </motion.div>

        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-notes-medical'></i>
          <h2>24/7 Services</h2>
          <p>At Medical Hints Nepal, we understand that health emergencies can happen at any time, and access to quality medical assistance is crucial.</p>
        </motion.div>

        {/* Add motion.div to each service card */}
        <motion.div className='service-card'
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
        >
          <i className='fas fa-rotate'></i>
          <h2>Warranty and Exchange</h2>
          <p>At Medical Hints Nepal, we value the satisfaction and trust of our customers. To ensure a seamless shopping experience, we offer a warranty and exchange policy that covers eligible products.</p>
        </motion.div>
      </div>
    </div>
  );
};

export default OfferService;
