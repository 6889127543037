import React from 'react'
import ContactImage from './ContactImage'
import Footer from '../../element/Footer/Footer'
import CreateForm from '../../element/Form/CreateForm'
import Embed from '../../element/Embed/Embed'
import ContactText from './ContactText'
import Scroll from '../../element/Scroll/Scroll'

const OurContact = () => {
  return (
    <>
      <ContactText/>
      <CreateForm/>
      <Scroll/>
      <Footer/>
    </>
  )
}

export default OurContact
