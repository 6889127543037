import React from "react";
import "./ServiceCard.css";
import image1 from '../../images/slider3-min.jpg'

function ServiceCard() {
  return (
    <div className="serv-container">
      <div className="serv-content">
        <h2 className="animated-text">Medical Hints Nepal</h2>
        <p className="animated-paragraph">Medical Hints Nepal is a leading provider of medical equipment and supplies in Nepal. With a focus on quality and reliability, we strive to meet the healthcare needs of our customers across the country. Our wide range of products includes diagnostic equipment, surgical instruments, medical consumables, and much more.</p>
        <img
          className="animated-image"
          src={image1}
          alt="Animated"
        />
      </div>
    </div>
  );
}

export default ServiceCard;
