import React from 'react'
import HomeSlider from '../../element/Slider/HomeSlider'

import CreateForm from '../../element/Form/CreateForm'
import Scroll from '../../element/Scroll/Scroll'
import Footer from '../../element/Footer/Footer'
import Testimonial from '../../element/Testimonial/Testimonial'
import Social from '../../element/Social/Social'
import Brand from '../../element/Brand/Brand'
import Product from '../../element/Product/Product'
import HomeSlider2 from '../../element/Slider/HomeSlider2'

const Home = () => {
  return (
    <>
    
    <HomeSlider/>
    <HomeSlider2/>
    <Social/>
    <Product/>
    <Testimonial/>
    <Scroll/>
    <Footer/>
    

    
   
      
    </>
  )
}

export default Home
