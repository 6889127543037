import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './HomeSlider.css';

import image3 from '../../images/slider5-min.jpg';
import image4 from '../../images/slider4-min.jpg';

const HomeSlider2 = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <div
          className="parallax-bg"
          style={{ backgroundImage: `url(${image3})` }}
        >
          <div className="slide-content">
            <h2>Tynor Orthopedic Products</h2>
            <p>Discover our range of Tynor orthopedic products, designed to provide support and comfort for various orthopedic conditions.</p>
            <button>Read More</button>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          className="parallax-bg"
          style={{ backgroundImage: `url(${image4})` }}
        >
          <div className="slide-content">
            <h2>Tynor Standard Wheel Chair</h2>
            <p>
              The Tynor wheelchair provides comfort and mobility for those in need.
              With its durable construction and ergonomic design, it's the perfect
              solution for everyday use.
            </p>
            <button>Read More</button>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeSlider2;
