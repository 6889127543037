import React, {useState, useEffect} from 'react';
import './Testimonial.css'
import data from './data'
import { Parallax } from "react-parallax";
import dark from '../../images/dark-min.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Testimonial = () => {
    const [people] = useState(data);
    const[index, setIndex] = useState(0);

    useEffect(() => {
        const lastIndex = people.length - 1;
        if(index < 0) {
            setIndex(lastIndex);

        }
        if(index > lastIndex) {
            setIndex(0);
        }
    }, [index, people]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1)
        }, 7000);
        return () => {
            clearInterval(slider)
        }
    }, [index])
    return(
        <div id='testimonials'> 
        <Parallax strength={400} bgImage={dark} >
        <section className="test-section" >
            <div className="title">
                <h2>Happy & Healthy Patients</h2>
            </div>
            <div className="section-center">
                {people.map((item, indexPeople) => {
                    const {id, image, name, title, quote} = item;
                    let position = 'nextSlide';
                    if(indexPeople === index){
                        position= 'activeSlide'
                    }
                    if(indexPeople === index - 1 || (index === 0 && indexPeople === people.length - 1)){
                        position= 'lastSlide'
                    }
                    return(
                        <article className={position} key={id}>
                           <img src={image} alt={name} className='person_img'/>
                          
                            <p className="text">{quote}</p>
                            <h4>{name}</h4>
                            <p className="title">{title}</p>
                            
                        </article>
                    )
                })}
                <button className="prev" onClick={() => setIndex(index - 1)}>
                        <ArrowBackIosIcon/>
                </button>
                <button className="next" onClick={() => setIndex(index + 1)}>
                     <ArrowForwardIosIcon/>
                </button>
            </div>
        </section>
        </Parallax>
        </div>
        
    )
}
export default Testimonial
