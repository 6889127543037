import React, { useState } from 'react'
import './Product.css'
import { Link } from 'react-router-dom';
import heini from '../../images/opt1.png'
import oxy from '../../images/obj2.png'
import abs from '../../images/obj3.png'

import tynor from '../../images/obj4.png'
import Littmann from '../../images/obj6.jpg'
import rionet from '../../images/obj7.png'
import ankle from '../../images/airankle.png'
import classic from '../../images/litpink-removebg-preview.png'
import rib from '../../images/backsupport-removebg-preview.png'





const Product = () => {
    const products = [
        {
          name: 'HEINE MINI 3000 LED Ophthalmoscope',
          description: 'Concentrated bright light for perfect illumination, a brilliant image and accurate colour rendering having 5 different apertures. ',
          image: heini,
        },
        {
          name: 'Oxywatch Pulse Oximeter',
          description: 'Simple To Operate And Convenient To Carry for small volume, Light Weight, And Low Power Consumption and auto power off',
          image: oxy,
       

        },
        {
          name: 'ABS WRAP NEOPRENE',
          description: 'Abdominal belt- supports and compresses the abdominal muscles, used as a post-operative aid. Can also be used in ventral hernia.',
          image: abs,
   
        },
        {
          name: 'Tynor Lumbo Back Rest Urbane',
          description: 'Tynor Lumbo-Back Rest Urbane is portable back support, which maintains the lumbar spine in its neutral position.',
          image: tynor,
       
        },
        {
          name: ' Littmann Master Cardiology Stethoscope',
          description: '3M Littmann Master Cardiology Stethoscope, Black Plated Chestpiece and Eartubes,Black Tube, 27 inch.',
          image: Littmann,
         
        },
        {
          name: 'RIONET HA-20DX Hearing Aids',
          description: 'Body Worn type analog hearing aid and affordable, Bigger range, suitable for forgetful elderly.',
          image: rionet,
         
        },
        {
          name: ' TYNOR ANKLE BINDER URBANE',
          description: 'The Tynor Ankle Binder Urbane is a trendy and effective device to support, compress and partially immobilize the ankle following injury or sprain to control pain, oedema or inflammation. Composed of two components, compression sleeve and elasticized strap. The elasticized strap can be conveniently wrapped in a figure of 8 for strong and effective ankle support.',
          image: ankle,
          type: 'Tynor'
        },

        {
          name: '3M™ Littmann® - Classic III™ Stethoscope - Pearl Pink',
          description: '3M™ Littmann® Classic III™ Stethoscope This is the perfect all-around stethoscope -available in vibrant colors Latex Free 5 Year Warranty Monitor and assess a wide range of patients Detect normal and abnormal sounds and rhythms  Vibrant and fun colors and finishes are available Designed for use with adult and pediatric patients Tunable, dual- sided stainless steel chestpiece.',
          image: classic,
          type: 'Littmann'
        },
        {
          name: 'Tynor Rib Belt',
          description: 'Tynor Rib Belt, Size: XXL is a premium quality product from Tynor. Moglix is a well-known ecommerce platform for qualitative range of Waist & Abdomen Supports. All Tynor Rib Belt, Size: XXL are manufactured by using quality assured material and advanced techniques, which make them up to the standard in this highly challenging field.',
          image: rib,
          type: 'Tynor'
        },
      ];

      const [selectedProduct, setSelectedProduct] = useState(null);

      const handleLearnMore = (index) => {
        setSelectedProduct(products[index]);
      };
    
      const handleClose = () => {
        setSelectedProduct(null);
      };



  return (
    <>

        <section className='product-section' id='product'>
            <h2>Our Products</h2>
            <div className="product-grid">
                {products.map((product, index) => (
                <div key={index} className="product-card">
                    <img src={product.image} alt={product.name} />
                    <h3>{product.name}</h3>
                  {/* <p>{product.description}</p> */}
                    <p>{product.price}</p>
                    <button onClick={() => handleLearnMore(index)} className="btn">
                      Learn More
                    </button>
                </div>
                ))}
            </div>
        </section>


        {selectedProduct && (
        <div className="product-modal">
          <div className="product-modal-content">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
            <h3>{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
            < Link to="./contact" className="buy-btn">
              Buy Now
            </Link>
          </div>
          <button className="close-btn" onClick={handleClose}>
            <i className="fas fa-times"></i>
            </button>
        </div>
      )}
        
    </>
  )
}

export default Product
