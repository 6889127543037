import React from 'react'
import './OurServiceText.css'

const OurServiceText = () => {
  return (
    <>

  <section className="features-section">
	      <div className="feature-container">
				      <div className="features-box bg1">
					      <img src="images/calendar.png" alt=""/>
						  <h3>24 Hours Services</h3>
						  <p>We serve a resource for people seeking medical assistance and information in Nepal around the clock</p>
					 </div>
				 </div>
				 
				 
				 <div className="feature-container">
				      <div className="features-box bg2">
					      <img src="images/staff.png" alt=""/>
						  <h3>Professional Staff</h3>
						  <p> We Highlight the importance of well-trained and skilled healthcare professionals in the Nepalese medical sector</p>
					 </div>
				 </div>
				 
         <div className="feature-container">
				      <div className="features-box bg3">
					      <img src="images/ambulance.png" alt=""/>
						  <h3>Emergency Care</h3>
						  <p>We provide comprehensive information and resources related to emergency medical services and care available across Nepal</p>
					 </div>
				 </div>
				 
	</section>
      
    </>
  )
}

export default OurServiceText
