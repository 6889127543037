import React from 'react';
import './ServiceText.css';



const ServiceText = () => {
 
  return (
    <>
      <div className="image-section">
        <h2>OUR SERVICES</h2>
      </div>
  
    </>
  );
};

export default ServiceText;
