import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faEnvelope, faLocation, faMobile, faPhone, faPhoneAlt, faSquarePhone, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/logo.jpg'; // Replace with the actual path to your logo image
import './Footer.css'

function Footer() {

  return (
    <footer className="footer parallax-footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>About Us</h3>
          <img src={logo} height={90} alt="Logo" />
          <p>"Quality services you can trust beyond expectations"</p>
        </div>
        <div className="footer-column">
          <h3>Contact</h3>
          <p><FontAwesomeIcon icon={faEnvelope} className='icons-footer' /> Email: <a href="https://mail.google.com/mail/u/0/#inbox">medicalhintsnepal@gmail.com</a></p>
          <p><FontAwesomeIcon icon={faSquarePhone} className='icons-footer'/> Phone Number: <a href="#"> 9868133933</a></p>
          <p><i className="fa-brands fa-whatsapp"></i> Whatsapp Number:  <a href="#">9808962431</a></p>
          <p><FontAwesomeIcon icon={faLocation} className='icons-footer'/>Address: <a>Naxal, Bhatbhateni-4, Kathmandu, Nepal</a></p>
        </div>
        <div className="footer-column"> 
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/medicalhintsnepal"><i className="fa-brands fa-facebook"></i></a> 
            <a href="https://www.instagram.com/medicalhintsnepal"><i className="fa-brands fa-instagram"></i></a>
            
          </div>
        </div>
      </div>
      <div className="footer-separator"></div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Medical Hints Nepal | All Rights Reserved.</p>

      </div>
    </footer>
  );
}

export default Footer;
