import React from 'react';
import './AboutText.css';
import dark from '../../images/medback-min.jpg';
import { Parallax } from 'react-parallax';
import logo from '../../images/logo.jpg'

const AboutText = () => {
  return (
    <>
      <div className='about-wrapper'>
        <Parallax strength={300} bgImage={dark} className='Parallax'>
          <div className='about-row'>
            <div className='image-content'>
              <h1>About Us</h1>
              <p>
                MedicalhintsNepal is your leading source for medical equipment supplies. We offer a wide selection of products to meet the needs of medical professionals, hospitals, and healthcare facilities worldwide. We specialize in providing quality medical equipment and supplies that are efficient, safe, and reliable. Our commitment to quality ensures that you will have the best possible products to meet your needs.
              </p>
              <p>
                We offer a variety of equipment and supplies, including medical disposables, laboratory equipment, and medical supplies. We are proud to provide our customers with top-notch customer service and a wide selection of products. Whether you need medical equipment for a one-time use or for long-term use, we have the right solution for you.
              </p>
              <p>
                At MedicalhintsNepal, we strive to provide our customers with top-notch service and products. We understand that medical professionals need to have reliable and safe equipment and supplies in order to provide optimal care to their patients. Our team of knowledgeable and professional staff is here to help you find the best possible solution for your needs.
              </p>
            </div>
            <div className='image-wrapper'>
              <img src={logo} alt='Background' />
            </div>
          </div>
        </Parallax>
       
      </div>
    </>
  );
};

export default AboutText;
