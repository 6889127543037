import React, { useState } from 'react';
import './MedicalCon.css';
import { Link } from 'react-router-dom';
import heini from '../../images/littmansteth.png';
import oxy from '../../images/obj2.png';
import abs from '../../images/obj3.png';
import tynor from '../../images/obj4.png';

import Footer from '../../element/Footer/Footer';

const MedicalCon = () => {
  const [products, setProducts] = useState([
    {
      name: '3M Aura Original N95 Mask - 1pcs',
      description: '',
      image: heini,
      type: 'Mask'
    },
    {
      name: 'Surgical Gloves &amp; Disposable',
      description: '',
      image: oxy,
      type: 'Gloves'
    },
    {
      name: 'Blood Collection Tubes',
      description: '',
      image: abs,
      type: 'Blood'
    },
    {
      name: 'I.V. Infusion Set With Airvent And BCV',
      description: '',
      image: tynor,
      type: 'Infusion'
    },
 
   
   
  ]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');


  const handleLearnMore = (index) => {
    setSelectedProduct(products[index]);
  };

  const handleClose = () => {
    setSelectedProduct(null);
  };

  const handleFilter = (type) => {
    if (type === 'all') {
      setFilterType(null);
    } else {
      setFilterType(type);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product => {
    if (!filterType || product.type === filterType) {
      return product.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });


  return (
    <>
      <section className='medical-section' id='product'>
        <h2>Our Products</h2>
        <div className="filter-buttons">
          <button onClick={() => handleFilter('all')}>All</button>
    

        </div>
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="medical-grid">
          {filteredProducts.map((product, index) => (
            <div key={index} className="medical-card">
           {/*  <img src={product.image} alt={product.name} /> */}
              <h3>{product.name}</h3>
              {/* <p>{product.description}</p> */}
              <button onClick={() => handleLearnMore(index)} className="medical-btn">
                Learn More
              </button>
            </div>
          ))}
        </div>
      </section>

      {selectedProduct && (
        <div className="product-modal">
          <div className="product-modal-content">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
            <h3>{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
            <Link to="./contact" className="buy-btn">
              Buy Now
            </Link>
          </div>
          <button className="close-btn" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}

      <Footer/>
    </>
  )
}

export default MedicalCon;
