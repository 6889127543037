import React, { useState } from 'react'
import './MedicalEq.css'
import { Link } from 'react-router-dom';
import litmann from '../../images/3mlittmanmastercardiology-removebg-preview.png'
import litmann4 from '../../images/littmansteth.png'
import litmann3 from '../../images/obj6.jpg'
import otoscope from '../../images/opt1.png'
import mdf from '../../images/littman1.png'
import Footer from '../../element/Footer/Footer';





const MedicalDressing = () => {
    const products = [
        {
          name: 'Gauze Sponges',
          description: '',
          image: litmann,
        },
        {
          name: 'Medical Tape',
          description: '',
          image: litmann4,
      
        },
        {
          name: 'Non-Woven Sponges',
          description: '',
          image:  litmann3,
        },
        {
          name: 'Alcohol Pads',
          description: '',
          image:  otoscope,
       
        },
        {
          name: 'Bandages & Dressings',
          description: '',
          image:  otoscope,
    
        },
        {
          name: 'Suture Removal kids',
          description: '',
          image:  mdf,
         
        },
        {
            name: 'BMNEXCARE- Advanced Healing Waterproof Bandage',
            description: '',
            image:  litmann,
           
          },
       
      ];

      const [selectedProduct, setSelectedProduct] = useState(null);
      const [filterType, setFilterType] = useState(null);
      const [searchTerm, setSearchTerm] = useState('');
    
    
      const handleLearnMore = (index) => {
        setSelectedProduct(products[index]);
      };
    
      const handleClose = () => {
        setSelectedProduct(null);
      };
    
      const handleFilter = (type) => {
        if (type === 'all') {
          setFilterType(null);
        } else {
          setFilterType(type);
        }
      };
    
      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredProducts = products.filter(product => {
        if (!filterType || product.type === filterType) {
          return product.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });


  return (
    <>

    <section className='medical-section' id='product'>
        <h2>Our Products</h2>
        <div className="filter-buttons">
          <button onClick={() => handleFilter('all')}>All</button>
          <button onClick={() => handleFilter('Littmann')}>Littmann</button>
          <button onClick={() => handleFilter('Tynor')}>Tynor</button>
        </div>
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="medical-grid">
          {filteredProducts.map((product, index) => (
            <div key={index} className="medical-card">
            {/*  <img src={product.image} alt={product.name} /> */}
              <h3>{product.name}</h3>
              {/* <p>{product.description}</p> */}
              <button onClick={() => handleLearnMore(index)} className="medical-btn">
                Learn More
              </button>
            </div>
          ))}
        </div>
      </section>

      {selectedProduct && (
        <div className="product-modal">
          <div className="product-modal-content">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
            <h3>{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
            <Link to="./contact" className="buy-btn">
              Buy Now
            </Link>
          </div>
          <button className="close-btn" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}

      <Footer/>         
    </>
  )
}

export default MedicalDressing
