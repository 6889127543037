import React from 'react';
import './ContactText.css';


const ContactText = () => {
  return (
    <>
    <div className="image-section-con">
      <h2>CONTACT US</h2>
    </div>
     
    
   </>
  );
};

export default ContactText;
