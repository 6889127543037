  import React, { useState } from 'react';
  import emailjs from 'emailjs-com'; // Import EmailJS library
  import './CreateForm.css'; // Import CSS file for styling

  const CreateForm = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      contact: '',
      message: '',
    });

    const [formErrors, setFormErrors] = useState({
      name: '',
      email: '',
      contact: '',
      message: '',
    });

    const [submissionStatus, setSubmissionStatus] = useState(null); // Track submission status

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });

      // Validate the field as the user types and update the error state
      validateField(name, value);
    };

    const validateField = (fieldName, value) => {
      let error = '';
      switch (fieldName) {
        case 'name':
          error = value.trim() ? '' : 'Name is required';
          break;
        case 'email':
          error = value.trim() ? (isValidEmail(value) ? '' : 'Invalid email format') : 'Email is required';
          break;
        case 'contact':
          error = value.trim() ? (isValidContact(value) ? '' : 'Invalid contact number format') : 'Contact number is required';
          break;
        case 'message':
          error = value.trim() ? '' : 'Message is required';
          break;
        default:
          break;
      }
      setFormErrors({
        ...formErrors,
        [fieldName]: error
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      let errors = {};

      // Basic validation
      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }

      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Invalid email format';
      }

      if (!formData.contact.trim()) {
        errors.contact = 'Contact number is required';
      } else if (!isValidContact(formData.contact)) {
        errors.contact = 'Invalid contact number format';
      }

      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      }

      setFormErrors(errors);

      // If there are no errors, you can submit the form
      if (Object.keys(errors).length === 0) {
        // Send email using EmailJS
        emailjs.sendForm('service_3beov1b', 'template_nu7nh85', e.target, 'zkrKjVNaPdpyVgst6')
          .then((result) => {
            console.log('Email sent successfully:', result.text);
            // Reset form after successful submission
            setFormData({
              name: '',
              email: '',
              contact: '',
              message: '',
            });
            // Set submission status to true
            setSubmissionStatus(true);
          }, (error) => {
            console.error('Email sending failed:', error.text);
            // Set submission status to false
            setSubmissionStatus(false);
          });
      }
    };

    const isValidEmail = (email) => {
      // Basic email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isValidContact = (contact) => {
      // Basic contact number validation regex (for example, allowing only digits and hyphens)
      const contactRegex = /^[0-9-]+$/;
      return contactRegex.test(contact);
    };

    return (
      <div className="contact-form-container">
        {/* Left side: Map */}
        <div className="map-container">
          {/* Embed your map component here */}
          {/* For example: <MapComponent /> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d220.74693298507069!2d85.33089913168635!3d27.718801370017538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191288c8f30f%3A0x64d98a3aa092b5fb!2sBhatbhateni%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1685858820815!5m2!1sen!2snp" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>

        {/* Right side: Email Form */}
        <div className="email-form-container">
          <h2>Get In Touch With Us</h2>
          {submissionStatus === true && <p className="success-message">Form submitted successfully!</p>}
          {submissionStatus === false && <p className="error-message">Failed to submit form. Please try again later.</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name"></label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" />
              {formErrors.name && <span className="error-message">{formErrors.name}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email"></label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" />
              {formErrors.email && <span className="error-message">{formErrors.email}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="contact"></label>
              <input type="text" id="contact" name="contact" value={formData.contact} onChange={handleChange} placeholder="Enter your contact number" />
              {formErrors.contact && <span className="error-message">{formErrors.contact}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="message"></label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message"></textarea>
              {formErrors.message && <span className="error-message">{formErrors.message}</span>}
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    );
  };

  export default CreateForm;
