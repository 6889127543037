import React from 'react'
import Footer from '../../element/Footer/Footer'
import Serviceimage from './Serviceimage'
import OfferService from './OfferService'
import Scroll from '../../element/Scroll/Scroll'
import ServiceCard from './ServiceCard'
import OurServiceText from './OurServiceText'
import ServiceText from './ServiceText'

const OurService = () => {
  return (
    <>
    <ServiceText/>
    <OurServiceText/>
    <OfferService/>
    <Serviceimage/>
    <ServiceCard/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default OurService
